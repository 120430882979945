loadAUIShim();
import domLoaded from 'dom-loaded';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import '../scss/global.scss';
import TheRedesignHeader from './components/redesign/TheHeader.vue';
import TheFooter from './components/TheFooter.vue';
import { Site } from './enums/Site';
import { useContextStore } from './stores/contextStore';
import { useLanguagesStore } from './stores/languagesStore';
import { useVendorStatusStore } from './stores/vendorStatusStore';
import errorHandlerPlugin from './util/errorHandlerPlugin';
import initVueI18n from './util/i18n';
import katalMetricsPlugin from './util/katalMetricsPlugin';
import localePlugin from './util/localePlugin';
import initNavConversionMetrics from './util/navConversionMetrics';
import { useWindowStore } from './stores/windowStore';
import { auiMonsAppAllowList } from '../aui/allowList.js';
import getMetricsPublisher from '../js/util/katalMetricsPublisher.js';

async function onReady() {
    const header = createApp(TheRedesignHeader);
    const footer = createApp(TheFooter);
    const pinia = createPinia();
    const i18n = await initVueI18n();
    // These values are replaced by Katal, see https://katal.amazon.dev/build-and-deploy/tutorials/deployment-manifest-templating/ for more details
    const stage = 'prod';
    const region = 'USAmazon';

    for (const app of [header, footer]) {
        for (const plugin of [errorHandlerPlugin, pinia, i18n, localePlugin, katalMetricsPlugin]) {
            app.use(plugin);
        }
        app.config.globalProperties.$stage = stage;
        app.config.globalProperties.$region = region;
    }

    useWindowStore().initializeResizeListener();
    const contextStore = useContextStore();
    contextStore.loadContext();
    if (contextStore.site !== Site.ADVANTAGE) {
        useLanguagesStore().loadLanguages();
    }
    useVendorStatusStore().loadVendorStatus();
    loadVibesScript();

    header.mount('#vc-header-container');
    footer.mount('#vc-footer-container');

    initNavConversionMetrics();
}

function loadAUIShim() {
    const monsAppName = document.head.querySelector('meta[name="a2z:mons_app_name"]')?.content;
    if (monsAppName && auiMonsAppAllowList.includes(monsAppName)) {
        const metricsPublisher = getMetricsPublisher();
        if (window.AmazonUIPageJS) {
            // only load shim when AUI is needed on the page to avoid js errors
            let shouldLoadAUICSS = true;
            import('../aui/aui-shim.js')
                .catch((error) => {
                    shouldLoadAUICSS = false; // skip loading css if we run into an issue loading the JS (like AUI already existing), to avoid conflicting styles with page owners
                    if (error.message?.includes('jQuery already registered by')) {
                        // This error is harmless so we swallow it and just log to console instead of blowing up
                        console.log('Error importing AUI shim: ', error.message);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    metricsPublisher
                        .newChildActionPublisherForMethod('AuiShim')
                        .publishCounterMonitor(`loaded_js.${monsAppName}`, 1);
                    if (shouldLoadAUICSS) {
                        import('../aui/aui-shim.scss');
                        metricsPublisher
                            .newChildActionPublisherForMethod('AuiShim')
                            .publishCounterMonitor(`loaded_css.${monsAppName}`, 1);
                    }
                });
        } else {
            metricsPublisher
                .newChildActionPublisherForMethod('AuiShim')
                .publishCounterMonitor(`loaded_js.${monsAppName}`, 0);
            metricsPublisher
                .newChildActionPublisherForMethod('AuiShim')
                .publishCounterMonitor(`loaded_css.${monsAppName}`, 0);
        }
    }
}

function loadVibesScript(stage, region) {
    let scriptURL;

    if (stage === 'prod') {
        switch (region) {
            case 'EUAmazon':
                scriptURL = 'https://d2xz78i0uf24on.cloudfront.net';
                break;
            case 'FEAmazon':
                scriptURL = 'https://d2uoea3f4olihz.cloudfront.net';
                break;
            default:
                scriptURL = 'https://d1qhz7cfyjhrcf.cloudfront.net';
        }
    } else {
        switch (region) {
            case 'EUAmazon':
                scriptURL = 'https://d21qaigptwqi6g.cloudfront.net';
                break;
            case 'FEAmazon':
                scriptURL = 'https://d1nkhofe5yzfzx.cloudfront.net';
                break;
            default:
                scriptURL = 'https://d1awn7zroxtggv.cloudfront.net';
        }
    }

    const scriptEl = document.createElement('script');
    scriptEl.src = `${scriptURL}/vibes_loader.latest.js`;
    document.head.appendChild(scriptEl);
}

domLoaded.then(onReady);
